<template>
  <section class="container">
    <router-link to="/">{{ t("Home Page") }}</router-link>
    <keep-alive>
      <router-link v-for="crumb in crumbs" :key="crumb" :to="crumb.to">
        {{ t(crumb.text) }}
      </router-link>
    </keep-alive>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    selected: {
      type: String,
    },
  },
  computed: {
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      let clearArray = [];
      pathArray.shift();
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i] !== "ru" && pathArray[i] !== "uk") {
          clearArray.push(pathArray[i]);
        }
      }
      let breadcrumbs = clearArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, []);

      if (this.selected) {
        breadcrumbs[breadcrumbs.length - 1].text = this.selected;
      }

      return breadcrumbs;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1366px;
  @include property("padding-top", 25, 15);
  @include property("margin-bottom", 30, 20);

  a {
    position: relative;
    font-size: 16px;
    line-height: calc(25 / 13);
    letter-spacing: 0.65px;
    color: #000000;
    
    .dark-bg & {
      color: #f9b80e;
    }

    &:not(:last-child) {
      margin-right: 42px;
      &::before {
        content: "";
        position: absolute;
        top: 10px;
        right: -20px;
        transform: translateX(50%);
        width: 11px;
        height: 11px;
        background: #000;
        mask-image: url("~@/assets/images/arrow-dropdown.svg");
        mask-size: 100% 100%;
        mask-repeat: no-repeat;
        pointer-events: none;

        .dark-bg & {
          background: #f9b80e;
        }
      }
    }

    &:last-child {
      color: #f9b80e;
      pointer-events: none;
    }
  }
}
</style>
